/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shield-fill': {
    width: 10,
    height: 12,
    viewBox: '0 0 10 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.708.694a1.58 1.58 0 01.585 0l3.497.659c.315.059.544.334.544.655v4.27c0 1.58-.81 3.048-2.146 3.886l-1.59.998c-.366.229-.83.229-1.195 0l-1.59-.998A4.586 4.586 0 01.667 6.279V2.008c0-.32.228-.596.543-.655L4.708.694z" _fill="#fff"/>'
  }
})
